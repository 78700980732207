import React, { Fragment } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../components/SEO';
import { Main, Container } from '../components/Elements';
import Header from '../components/Header';
import FooterPre from '../components/FooterPre';
import { Hero } from '../components/HeroPages';
import { Heading, Flex, Box, Text, Image } from 'rebass';
import { StyleSquare } from '../components/StyleSquare';
import _map from 'lodash/map';
import fiText from '../locales/fi.js';

const Tyylit = () => {
  const data = useStaticQuery(tyylitQuery);
  const {
    seoMetaTags,
    content,
    heroLayer,
    imgHero,
    lead,
    title,
    otsikko1,
    otsikko2,
    teksti2,
    kuva2,
  } = data.datoCmsTyylit;

  const {
    tyyliLammin,
    tyyliRaikas,
    tyyliSisustusTumma,
    tyyliSisustusUniikki,
  } = data.datoCmsEtusivu;

  const introLayer = 'rgba(197, 151, 142, 0.8)';

  return (
    <Fragment>
      <SEO meta={seoMetaTags} />
      <Header />
      <Main bg={'peach'} color={'blueDark'} justifyContent={'center'} pb={6}>
        <Hero
          title={title}
          lead={lead}
          imgHero={imgHero}
          introLayer={introLayer}
          heroLayer={heroLayer}
          introColor={'blueDark'}
        />
        <Container justifyContent="center" mt={5}>
          <Heading fontSize={[5, 6]}>{otsikko1}</Heading>
        </Container>

        <Container>
          <Flex
            pl={[0, 0]}
            width={[1, 1 / 2]}
            flexWrap="wrap"
            justifyContent="center"
            mx="auto"
            className="tyylitSquares"
            pb={[0, 50, 180]}
            pt={[0, 10, 100]}
          >
            <Box width={'100%'} flexWrap="wrap">
              <Link to="/tyyli-ja-sisustus/raikas/">
                <StyleSquare img={tyyliRaikas} number={'1.'} title="Raikas" />
              </Link>
              <Link to="/tyyli-ja-sisustus/lammin">
                <StyleSquare img={tyyliLammin} number={'2.'} title="Lämmin" />
              </Link>
              <Link to="/tyyli-ja-sisustus/tumma">
                <StyleSquare
                  mt={1}
                  img={tyyliSisustusTumma}
                  number={'3.'}
                  title="Tumma"
                />
              </Link>
              <Link to="/tyyli-ja-sisustus/uniikki">
                <StyleSquare
                  mt={1}
                  img={tyyliSisustusUniikki}
                  title="Uniikki"
                  number={'4.'}
                />
              </Link>
            </Box>
          </Flex>
        </Container>
        <Container flexWrap="wrap" pb={[0, 20, 100]}>
          <Flex
            width={[1, 1 / 2]}
            my={0}
            justifyContent="center"
            flexDirection="column"
            textAlign="left"
          >
            <Heading fontSize={6} maxWidth={400}>
              {otsikko2}
            </Heading>

            <Text
              my={2}
              maxWidth={445}
              fontSize={3}
              fontWeight={300}
              dangerouslySetInnerHTML={{
                __html: teksti2,
              }}
            />
          </Flex>
          <Flex
            width={[1, 1 / 2]}
            my={0}
            flexWrap="wrap"
            justifyContent="flex-end"
          >
            <Box width={'100%'} maxWidth={'536px'}>
              <Img fluid={kuva2.fluid} alt={kuva2.alt} width={536} />
            </Box>
          </Flex>
        </Container>
      </Main>
      <FooterPre />
    </Fragment>
  );
};

const tyylitQuery = graphql`
  {
    datoCmsTyylit {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      lead
      content
      heroLayer {
        alpha
        blue
        green
        red
      }
      imgHero {
        fluid(maxWidth: 1600, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      otsikko1
      otsikko2
      teksti2
      kuva2 {
        fluid(maxWidth: 620, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
    }
    datoCmsEtusivu {
      tyyliLammin {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      tyyliRaikas {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      tyyliSisustusTumma {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
      tyyliSisustusUniikki {
        fluid(maxWidth: 536, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
        title
        alt
      }
    }
    datoCmsSivusto {
      tausta4 {
        url
      }
    }
  }
`;

export default Tyylit;
