import React from 'react';
import { Heading, Text, Box, Flex } from 'rebass';
import Img from 'gatsby-image';

export const StyleSquare = ({ img, number, title }) => (
  <Box
    width={['100%', '100%', '50%']}
    display="inline-flex"
    verticalAlign={'top'}
    overflow="hidden"
    maxWidth="536px"
    sx={{ position: 'relative' }}
  >
    <Box
      mr={[0, 1]}
      height={248}
      mb={1}
      overflow="hidden"
      width={['100%']}
      sx={{ position: 'relative' }}
    >
      <Img fluid={img.fluid} alt={img.alt} style={{ height: '100%' }} />
      <Flex
        className="tyyli"
        bg={'rgba(0,24,38,0.1)'}
        justifyContent="center"
        alignItems="center"
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          textTransform: 'uppercase',
        }}
      >
        <Box
          width={number == '4.' ? 140 : 140}
          height={number == '4.' ? 140 : 140}
          color={'white'}
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            borderStyle: number === '4.' ? 'solid' : 'solid',
            borderRadius: '50%',
            borderWidth: '2px',
            borderColor: 'white',
            background: 'rgba(0,0,0,0)',
          }}
        >
          <Heading
            as="h3"
            textAlign="center"
            fontSize={1}
            letterSpacing={'1px'}
            sx={{
              textShadow: '1px 2px 2px rgba(150, 150, 150, 1)',
            }}
          >
            {number}
            <br />
            {title}
          </Heading>
        </Box>
      </Flex>
    </Box>
  </Box>
);
